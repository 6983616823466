import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { NavLink, useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import PasswordField from '@/shared/components/form/Password';
import axios from 'axios';
import {
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import {
 AccountButton, AccountButtons, AccountForgotPassword, LoginForm, 
} from '../AccountElements';
import FormField from '../../form/FormField';
import * as environement from '../../../../environement';

const LogInForm = () => {
  const history = useHistory();
  const [error, setError] = useState(null);

  useEffect(() => {
    if(localStorage.getItem('accessToken')) {
      history.push('/wallet');
    }
  }, []);

  const handleSubmit = async (data) => {
    const { email, password } = data;
    
    if(!email || !password) {
      setError('Please fill in all fields');
      return;
    }

    if(!email.includes('@')) {
      setError('Please enter a valid email address');
      return;
    }

    if(password.length < 8) {
      setError('Password must be at least 8 characters');
      return;
    }

    if(password.length > 20) {
      setError('Password must be less than 20 characters');
      return;
    }

    if(password.search(/[^a-zA-Z0-9!@#$%^&*]/) !== -1) {
      setError('Password must not contain any spaces');
      return;
    }

    if(password.search(email) !== -1) {
      setError('Password must not contain your email address');
      return;
    }

    if(password.search('password') !== -1) {
      setError('Password must not contain the word "password"');
      return;
    }

    setError(null);

    try {
      const response = await axios.post(`${environement.config.apiUrl}/auth/signin`, data);
      if(response.status === 201) {
        localStorage.setItem('email', data.email);
        history.push('/two_factor');
      }
    }
    catch(err) {
      console.log(err);
      setError(err.response.data.message);
    }
  };
  
  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <LoginForm onSubmit={handleSubmit}>
          <Alert
            variant="danger"
            show={!!error}
            style={{ width: 'auto' }}
          >
            {error}
          </Alert>
          <FormGroup>
            <FormGroupLabel>Email</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <AccountOutlineIcon />
              </FormGroupIcon>
              <Field
                name="email"
                component={FormField}
                type="email"
                placeholder="your_email@mail.com"
                className="input-without-border-radius"
                required
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Password</FormGroupLabel>
            <FormGroupField>
              <Field
                name="password"
                component={PasswordField}
                placeholder="Password"
                className="input-without-border-radius"
                keyIcon
                required
              />
              <AccountForgotPassword>
                <NavLink to="/reset_password">Forgot a password?</NavLink>
              </AccountForgotPassword>
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupField>
              <br />
            </FormGroupField>
          </FormGroup>
          <AccountButtons>
          <AccountButton type="submit" variant="primary">Sign In</AccountButton>
            <AccountButton
              as={NavLink}
              variant="outline-primary"
              to="/register"
            >
              Create Account
            </AccountButton>
          </AccountButtons>
        </LoginForm>
      )}
    </Form>
  );
};

export default LogInForm;
