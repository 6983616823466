import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import Panel from '@/shared/components/Panel';
import getTooltipStyles from '@/shared/helpers';
import { DashboardAreaChartContainer } from '../../BasicDashboardComponents';

const ABTestingAnalytics = ({ dir, bot, task }) => {
  const { t } = useTranslation('common');
  const [data, setData] = useState([]);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const themeName = useSelector(state => state.theme.className);

  useEffect(() => {
    if(bot?.workersBalances) {
      setData(bot?.workersBalances);
    }
    if(task?.token) {
      setTokenSymbol(task?.token.symbol);
    }
  }, [bot, task]);

  return (
    <Panel md={12} lg={12} xl={12} title="Total sub wallets liquidity">
      <div dir="ltr">
        <DashboardAreaChartContainer height={250}>
          <AreaChart data={data} margin={{ top: 20, left: -15, bottom: 20 }}>
            <XAxis dataKey="hour" tickLine={true} reversed={dir === 'rtl'} />
            <YAxis tickLine={true} orientation={dir === 'rtl' ? 'right' : 'left'} />
            <Tooltip {...getTooltipStyles(themeName, 'defaultItems')} />
            {/* <Legend /> */}
            {/* <CartesianGrid /> */}
            <Area name="Native ($)" type="monotone" dataKey="native" fill="rgb(255,255,65)" stroke="rgb(255,255,65)" fillOpacity={0.2} />
            <Area name={tokenSymbol + " ($)"} type="monotone" dataKey="token" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} />
          </AreaChart>
        </DashboardAreaChartContainer>
      </div>
    </Panel>
  );
};

ABTestingAnalytics.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default ABTestingAnalytics;
