import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import showResults from '@/utils/showResults';
import Wizard from './components/WizardForm';
import { useHistory } from 'react-router-dom';
import { checkLicense } from '@/api';

const WizardForm = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  
  useEffect(() => {
    checkLicense().then((res) => {
      if(res.valid === false) {
        history.push('/subscription');
      }
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Configuring your bot</h3>
          <h3 className="page-subhead subhead">Please fill in the fields correctly to allow your bot to function perfectly
          </h3>
        </Col>
      </Row>
      <Wizard onSubmit={showResults} />
    </Container>
  );
};

export default WizardForm;
