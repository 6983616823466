import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Coins from './components/Coins';
import WalletInfo from './components/WalletInfo';
import Panel from '@/shared/components/Panel';
import { Button } from '@/shared/components/Button';
import { checkLicense } from '@/api';
import { useHistory, NavLink } from 'react-router-dom';
import axios from 'axios';
import * as environement from '../../../environement';

const Wallet = () => {
  const { t } = useTranslation('common');
  const rtl = useSelector(state => state.rtl);

  const [ifMainWallet, setIfMainWallet] = useState(null);
  const [license, setLicense] = useState(false);
  const [native, setNative] = useState(null);
  const [nativeBN, setNativeBN] = useState(null);
  const [avertMessage, setAvertMessag] = useState(false);
  
  useEffect(() => {
    checkIfLicense();
  }, []);

  const getNative = async () => {
    try {
      const response = await axios.get(`${environement.config.apiUrl}/user/getMainNativeBalance`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      setNative(response.data.native);
      setNativeBN(response.data.nativeBN);
    }
    catch (err) {
      console.error(err);
    }
  }

  const checkIfWallet = async () => {
    try {
      const response = await axios.get(`${environement.config.apiUrl}/user/me`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 200) {
        setIfMainWallet(response.data.mainWallet.address);
        await getNative();
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  const checkIfLicense = async () => {
    checkLicense().then(async (res) => {
      if(res.data.valid === true) {
        setLicense(true);
        await checkIfWallet();
      }
      if(res.data.valid === false && res.data.timestamp != null) {
        setAvertMessag(true);
        setLicense(true);
        await checkIfWallet();
      }
    });
  }

  const handleCreateWallet = async () => {
    try {
      const response = await axios.post(`${environement.config.apiUrl}/user/mainwallet/create`, {},{ headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 201) {
        setIfMainWallet(response.data.address);
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            {t('wallet.page_title')}
          </h3>
        </Col>
      </Row>
      {avertMessage && (
        <p style={{ color: 'red', marginLeft: '5px'}}>
          Your license has expired. <NavLink to="/subscription">Go to subscription page</NavLink>
          <br />
        </p>
      )}
      <Row>
        <WalletInfo ifMainWallet={ifMainWallet} native={native} nativeBN={nativeBN} dir={rtl.direction} />
        {!ifMainWallet && (
          <Panel xs={12} md={12} lg={4} color="warning" title="You do not yet have a main wallet !">
          <p className="typography-message">
          You have not yet created your main wallet, and we want to inform you of its crucial importance for creating bots on our platform. <br /><br />
          The main wallet is essential because it is the main means of managing the transactions and resources necessary for the operation of your bots. <br /><br />
          By creating your primary wallet, you ensure that you have a secure place to store funds and digital assets related to your bot development activities.
          <br /><br />
          {license ? (
            <Button variant="success" type="submit" onClick={handleCreateWallet}>Create my wallet now</Button>
          ) : (
            <p style={{ color: 'orange' }}>
              You do not have a valid license to create a wallet. 
              <br />
              <NavLink to="/subscription">Go to subscription page</NavLink>
            </p>
          )}
          </p>
          </Panel>
        )}
      </Row>
    </Container>
  );
};

export default Wallet;
