import { get } from './base/index';
import * as environement from '../../environement';

// Available api doc: "https://coinmarketcap.com/api/documentation/v1/#section/Quick-Start-Guide"
export default {
  getListingsLatest: (limit = 10, sort = 'market_cap') => {
    const params = {
      limit,
      sort,
    };
    return get(`${environement.config.apiUrl}/coinmarket/v1/cryptocurrency/listings/latest`, { params });
  },
  getQuotesBySymbol: (symbol) => {
    const params = { symbol };

    return get(`${environement.config.apiUrl}/coinmarket/v2/cryptocurrency/quotes/latest`, { params });
  },
  getGlobalQuotes: () => get(`${environement.config.apiUrl}/coinmarket/v1/global-metrics/quotes/latest`),
};
