import React, { useState, useEffect } from 'react'; // Import useState
import Slider from '@/shared/components/range_slider/Slider';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import renderToggleButtonField from '@/shared/components/form/ToggleButton';
import renderSelectField from '@/shared/components/form/Select';
import axios from 'axios';
import {
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import {
  WizardButtonToolbar,
  WizardDescription,
  WizardFormContainer,
  WizardTitle,
} from '@/shared/components/form/WizardFormElements';
import * as environement from '../../../../../environement';
import { useHistory } from 'react-router-dom';

const WizardFormThree = ({ onSubmit, previousPage, initialValues, onChange = (value) => {}, editing = false }) => {
  const history = useHistory();
  const [intervalBetween, setIntervalBetween] = useState(6);
  const [transactions, setTransactions] = useState(1440 / intervalBetween);
  const [amountPerTransaction, setAmountPerTransaction] = useState(initialValues.liquidityInjection / transactions);

  const getSymbol = async (address) => {
    const response = await axios.get(`https://node.checkdot.io/get-project-by-address?address=${address}`);
    return response.data[0]['symbol'];
  }

  const handleNextClick = async (values) => {

    let strategyData = { name: values.strategy.value };

    if (strategyData.name === 'flip') {
      strategyData.way = 'buy';
    } else if (strategyData.name === 'classic') {
      strategyData.chances = parseFloat(values.chanceToBuy);
    }

    const botData = {
      liquidityInjection: parseFloat(values.liquidityInjection),
      slippage: parseFloat(values.slippage),
      gasPrice: parseFloat(values.gasPrice),
      token: {
        address: values.token,
        decimals: parseFloat(values.tokenDecimals),
        symbol: await getSymbol(values.token),
      },
      // ...(values.strategy.value === 'classic' ? { chance: parseFloat(values.chanceToBuy) } : {}),
      //...(values.internalFees === undefined ? { internalFees: false } : { internalFees: values.internalFees }),
      strategy: strategyData,
      intervalBetween: intervalBetween,
      amountPerTransaction: parseFloat(amountPerTransaction.toFixed(0)),
      transactions: parseFloat(transactions.toFixed(0)),
      blockchain: values.blockchain.value,
      exchange: values.dex.value
    }

    try {
      const response = await axios.post(`${environement.config.apiUrl}/bot`, botData, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 201) {
        history.push('/bot_details');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleChangeAmount = (value) => {
    setIntervalBetween(value);
    setTransactions(1440 / value);
    setAmountPerTransaction(initialValues.liquidityInjection / (1440 / value));

    onChange({
      liquidityInjection: initialValues.liquidityInjection,
      interval: intervalBetween,
      transactions,
      amountPerTransaction
    });
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, values }) => (
        <WizardFormContainer horizontal onSubmit={handleSubmit}>
          {editing === false && (<><WizardTitle>Set the preferences</WizardTitle></>)}
          {editing === false && (<><FormGroup>
            <FormGroupLabel>Strategy</FormGroupLabel>
            <FormGroupField>
              <Field
                name="strategy"
                component={renderSelectField}
                type="text"
                options={[
                  { value: 'classic', label: 'Classic, play with luck' },
                  { value: 'flip', label: 'Flip, one buy, one sell' },
                ]}
                placeholder="Select strategy"
                isRequired
              />
            </FormGroupField>
          </FormGroup>
          {values?.strategy?.value === 'classic' && (<FormGroup>
            <FormGroupLabel>Chance in 10 of buying your token</FormGroupLabel>
            <FormGroupField>
              <Field
                name="chanceToBuy"
                component="input"
                type="number"
                placeholder="Enter amount (0 - 10)"
                min="0"
                max="10"
                isRequired
              />
            </FormGroupField>
          </FormGroup>)}</>)}
          <div style={{ color: '#dddddd'}}>
            <span>Interval between transactions: <code style={{ fontSize: '18px' }}> ~ {intervalBetween} minutes</code></span> <br />
            <span>Amount par transaction: <code style={{ fontSize: '18px' }}> ~ ${amountPerTransaction.toFixed()}</code></span> <br />
            <span>Total transactions: <code style={{ fontSize: '18px' }}>{transactions.toFixed()}</code></span>
          </div>
          <div style={{ width: '100%', color: '#dddddd', marginTop: '30px', marginBottom: '50px' }}>
            <span>Custom task</span>
            <Slider min={6} max={150} onChange={handleChangeAmount} />
          </div>
          {editing === false && (<><WizardButtonToolbar>
            <Button variant="primary" type="button" className="previous" onClick={previousPage}>Back</Button>
            <Button variant="success" onClick={() => handleNextClick(values)}>Create my bot</Button>
          </WizardButtonToolbar></>)}
        </WizardFormContainer>
      )}
    </Form>
  );
};

WizardFormThree.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    email_notifications: PropTypes.bool, // Adjust the shape according to your initialValues structure
  }).isRequired,
};

export default WizardFormThree;
