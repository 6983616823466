import React from 'react';
import { Col } from 'react-bootstrap';
import { Card } from '@/shared/components/Card';
import {
  PricingCard,
  PricingCardBody,
  PricingCardButton,
  PricingCardFeature,
  PricingCardImage,
  PricingCardPlan,
  PricingCardPrice,
} from './BasicElements';
import { NavLink } from 'react-router-dom';

const airplane = `${process.env.PUBLIC_URL}/img/pricing_cards/003-airplane.svg`;

const CardPro = ({ license }) => (
  <Col md={6} xl={3} sm={12}>
    <Card>
      <PricingCard color="warning">
        <PricingCardBody>
          <PricingCardImage src={airplane} alt="" />
          <PricingCardPlan>6 months</PricingCardPlan>
          <hr />
          <PricingCardPrice>$2750<span>/mo</span></PricingCardPrice>
          <PricingCardFeature>1 bot</PricingCardFeature>
          <PricingCardFeature inactive>Monthly update</PricingCardFeature>
          <PricingCardFeature inactive>Free support</PricingCardFeature>
          <PricingCardButton variant="primary" as={NavLink} to="/payment/six_months">
            {license ? 'Extend my license' : 'Buy now'}
          </PricingCardButton>
        </PricingCardBody>
      </PricingCard>
    </Card>
  </Col>
);

export default CardPro;
