import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import RegisterForm from '@/shared/components/account/login_register/LoginRegisterForm';
import {
  AccountCard,
  AccountContent,
  AccountHaveAccount,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';

const Register = () => {
  const history = useHistory();
  
  useEffect(() => {
    if(localStorage.getItem('accessToken')) {
      history.push('/wallet');
    }
  }, []);

  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>Welcome to
              <AccountLogo> AMP
                <AccountLogoAccent>BIT</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
            <h4 className="subhead">Create an account</h4>
          </AccountHead>
          <RegisterForm />
          <AccountHaveAccount>
            <p>Already have an account? <NavLink to="/log_in">Login</NavLink></p>
          </AccountHaveAccount>
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  );
};

export default withRouter(Register);
