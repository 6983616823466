import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import showResults from '@/utils/showResults';
import ResetPasswordForm from '@/shared/components/account/reset_password/ResetPasswordForm';
import {
  AccountCard,
  AccountContent,
  AccountHaveAccount,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';

const ResetPassword = props => {
  const history = useHistory();

  useEffect(() => {
    if(localStorage.getItem('accessToken')) {
      history.push('/wallet');
    }
  }, []);

  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>Welcome to
              <AccountLogo> AMP
                <AccountLogoAccent>BIT</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
            <h4 className="subhead">Password reset</h4>
          </AccountHead>
          <ResetPasswordForm
            {...props}
            onSubmit={showResults}
          />
          <AccountHaveAccount>
            <p>Remember your password?  <NavLink to="/log_in">Login</NavLink></p>
          </AccountHaveAccount>
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  );
}

export default ResetPassword;