import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PaymentCard from './components/PaymentCard';
import { getSubscriptionDetails } from '@/api';
import { useHistory } from 'react-router-dom';

const Payment = (props) => {
  const history = useHistory();
  
  const plans = {
    one_month: { name: '1 month', price: '500' },
    three_months: { name: '3 months', price: '1350' },
    six_months: { name: '6 months', price: '2750' },
    one_year: { name: '1 year', price: '5000' },
  };

  let selectedPlan = props.match.params.plan;
  const planInfo = plans[selectedPlan];
  
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Payment</h3>
          <h3 className="page-subhead subhead">Payment in USDT on the BNB Smart Chain</h3>
        </Col>
      </Row>
      <Row>
        {planInfo && (
          <PaymentCard plan={planInfo.name} price={planInfo.price} />
        )}
      </Row>
    </Container>
  );
};

export default Payment;
