import axios from "axios";
import * as environement from './environement';


export const checkLicense = async () => {
  try {
    const response = await axios.get(`${environement.config.apiUrl}/user/checkLicense`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
    return response;
  } catch (err) {
    console.error(err);
  }
}

export const getSubscriptionDetails = async () => {
  try {
    const response = await axios.get(`${environement.config.apiUrl}/user/subscription`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
    if(response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export const initPayment = async (plan, price) => {
  try {
    const response = await axios.post(`${environement.config.apiUrl}/user/initPayment`, { plan: plan, price: price } ,{ headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
    if(response.status === 201) {
      return response;
    }
  }
  catch(err) {
    console.log(err);
  }
}

export const checkIfBot = async () => {
  try {
    const response = await axios.get(`${environement.config.apiUrl}/bot/me`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
    if(response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export const getWorkers = async () => {
  try {
    const response = await axios.get(`${environement.config.apiUrl}/bot/worker`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
    if(response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export const getBalances = async (tokenAddress, tokenDecimals) => {
  try {
    const response = await axios.get(`${environement.config.apiUrl}/user/getMainWalletBalance/${tokenAddress}/${tokenDecimals}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
    if(response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export const getTokens = async () => {
  try {
    const response = await axios.get(`${environement.config.apiUrl}/user/tokens`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
    if(response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}