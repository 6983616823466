import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Panel from '@/shared/components/Panel';
import { Button } from '@/shared/components/Button';
import { StyledSlider } from '@/shared/components/carousel/CarouselElements';
import {
 colorAdditional, colorBackgroundBody, colorIcon, colorRed, colorWhite, 
} from '@/utils/palette';
import { left, right } from '@/utils/directions';
import Badge from 'react-bootstrap/Badge';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { apiUrl, getWorkers } from '@/api';
import * as environement from '../../../../environement';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  swipeToSlide: true,
  slidesToScroll: 1,
  responsive: [
    { breakpoint: 992, settings: { slidesToShow: 1 } },
    { breakpoint: 1200, settings: { slidesToShow: 1 } },
    { breakpoint: 1536, settings: { slidesToShow: 1 } },
    { breakpoint: 100000, settings: { slidesToShow: 1 } },
  ],
};

const ShortReminders = ({ bot, task, balances, workers }) => {
  const { t } = useTranslation('common');
  const [atcWorker, setAtcWorker] = useState(false);

  const handleAddWorker = async () => {
    setAtcWorker(true);
    try {
      const response = await axios.post(`${environement.config.apiUrl}/bot/worker`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 201) {
        await getWorkers();
      }
      setTimeout(() => {
        setAtcWorker(false);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  }

  const handleRemoveWorker = async () => {
    setAtcWorker(true);
    try {
      const response = await axios.delete(`${environement.config.apiUrl}/bot/worker`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 200) {
        await getWorkers();
      }
      setTimeout(() => {
        setAtcWorker(false);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Panel title="Wallets">
      <p style={{ width: '100%', display: 'flex' }}>
        <Badge bg="warning" text="black" style={{ fontSize: '13px', width: '50%' }}>
            All Native : <strong>
              {balances.native !== undefined ? '$' + (Number(balances.native) + workers.reduce((acc, worker) => acc + Number(worker.nativeBalance), 0)).toFixed(2) : ''}
            </strong>
        </Badge>

        <Badge bg="success" style={{ fontSize: '13px', width: '50%' }}>
            All {task?.token?.symbol} : <strong>
              {balances.token !== undefined ? '$' + (Number(balances.token) + workers.reduce((acc, worker) => acc + Number(worker.balanceToken), 0)).toFixed(2) : ''}
            </strong>
        </Badge>
      </p>
      <p style={{ marginTop: '10px', width: '100%', display: 'flex' }}>
        <Badge bg="warning" text="black" style={{ fontSize: '13px', width: '50%' }}>
          Main Native : <strong>
              {!balances.native ? (
                <Spinner animation="border" size="sm" />
              ) : (
                `$${balances.native}`
              )}
          </strong>
        </Badge>
        &nbsp;
        <Badge bg="success" style={{ fontSize: '13px', width: '50%' }}>
            Main {task?.token?.symbol} : <strong>
              {!balances.token ? (
                <Spinner animation="border" size="sm" />
              ) : (
                `$${balances.token}`
              )}
          </strong>
        </Badge>
      </p>
      <br />
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button type="submit" onClick={handleAddWorker} disabled={bot?.status != 'stopped' ? true : false } variant="success">
          Add new sub wallet
        </Button>
        <Button type="submit" onClick={handleRemoveWorker} disabled={bot?.status != 'stopped' ? true : false} variant="danger">
          Delete one sub wallet
        </Button>
      </div>
      {atcWorker && (
        <p style={{ fontSize: '15px', marginBottom: '10px' }}>
        <center>
          <Spinner animation="border" size="sm" /> &nbsp; Loading
        </center>
        </p>
      )}
      {workers.length === 0 && (
        <Alert
          variant="danger"
        >
         <center>You must add at least <strong>two</strong> sub wallets</center>
        </Alert>
      )}
      <DashboardRemindersCarousel {...settings}>
        {workers.map((worker, index) => (
          <div key={index}>
            <DashboardRemindersSlide>
              <DashboardRemindersSlideTitle>
                Wallet {index + 1} / {workers.length}
              </DashboardRemindersSlideTitle>
              <p style={{ fontSize: '16px' }}>
                <Badge bg="warning" text="dark">Native : ${worker.nativeBalance}</Badge>&nbsp;
                {bot?.exchange === 'Pancakeswap V3' && (
                  <>
                    <Badge bg="primary">WBNB : ${worker.wbnbBalance}</Badge> &nbsp;
                  </>
                )}
                <Badge bg="success">{task?.token?.symbol} : ${worker.balanceToken}</Badge>
              </p>
            </DashboardRemindersSlide>
            <br />
          </div>
        ))}
      </DashboardRemindersCarousel>
    </Panel>
  );
};

export default ShortReminders;

// region STYLES

const DashboardRemindersCarousel = styled(StyledSlider)`
  margin-bottom: -25px;

  .slick-dots {
    bottom: -35px;
  }

  .slick-arrow.slick-prev, .slick-arrow.slick-next {
    background: none;
    opacity: 0;
    pointer-events: none;
  }
`;

const DashboardRemindersSlide = styled.div`
  text-align: ${left};
  border-radius: 5px;
  padding: 15px 13px;
  position: relative;
  overflow: hidden;
  background: ${colorBackgroundBody};

  svg {
    position: absolute;
    height: 80px;
    width: 80px;
    top: calc(50% - 40px);
    opacity: 0.3;
    ${right}: -20px;
    fill: ${colorIcon};
  }

  p:last-of-type {
    color: ${colorAdditional};
    margin: 0;
  }
`;

const DashboardRemindersSlideTitle = styled.p`
  color: rgb(255, 255, 65);
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
`;

const DashboardRemindersSlideRed = styled(DashboardRemindersSlide)`
  background: ${colorRed};

  p:last-of-type {
    color: white;
    opacity: 0.45;
  }

  svg {
    fill: ${colorWhite};
  }

  ${DashboardRemindersSlideTitle} {
    color: white;
    opacity: 1;
  }
`;

// endregion
