import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import PasswordField from '@/shared/components/form/Password';
import axios from 'axios';
import {
 FormContainer, FormGroupField, FormGroupLabel, 
} from '@/shared/components/form/FormElements';
import {
  AccountButton,
  AccountForgotPassword,
  ForgotFormGroup,
} from '@/shared/components/account/AccountElements';
import { Alert } from 'react-bootstrap';
import * as environement from '../../../../environement';

const AccountForm = () => {
  const history = useHistory();
  const [error, setError] = useState(null);

  useEffect(() => {
    if(localStorage.getItem('accessToken')) {
      history.push('/wallet');
    }
  }, []);
  
  const handleSubmit = async (value) => {
    const { code } = value;
    if (!code) {
      setError('Please fill in all fields');
      return;
    }

    if (code.search(/[^a-zA-Z0-9!@#$%^&*]/) !== -1) {
      setError('Password must not contain any spaces');
      return;
    }

    setError(null);

    const data = {
      email: localStorage.getItem('email'),
      code,
    }

    try {
      const response = await axios.post(`${environement.config.apiUrl}/auth/signin/2fa`, data);
      if(response.status === 201) {
        localStorage.setItem('accessToken', response.data.accessToken);
        history.push('/wallet');
      }
    }
    catch(err) {
      console.log(err);
      setError(err.response.data.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          {!error && (
            <Alert
            variant="success"
            style={{ margin: 'auto' }}
          >
            A code has been sent to you!
          </Alert>
          )}
          <Alert
            variant="danger"
            show={!!error}
            style={{ width: 'auto', margin: 'auto' }}
          >
            {error}
          </Alert>
          <ForgotFormGroup>
            <FormGroupLabel>2FA Code</FormGroupLabel>
            <FormGroupField>
              <Field
                name="code"
                component={PasswordField}
                placeholder="2FA Code"
                keyIcon
                required
              />
              <AccountForgotPassword>
                <NavLink to="/reset_password">Forgot a password?</NavLink>
              </AccountForgotPassword>
            </FormGroupField>
          </ForgotFormGroup>
          <AccountButton type="submit" variant="primary">
            Unlock access
          </AccountButton>
        </FormContainer>
      )}
    </Form>
  );
};

export default AccountForm;