import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Panel from '@/shared/components/Panel';
import { Table } from '@/shared/components/TableElements';
import { useSelector, useDispatch } from 'react-redux';
import ErrorAlert from '@/shared/components/ErrorAlert';
import { fetchTopTenCurrencies } from '@/redux/actions/topTenActions';

const header = [
  { id: 0, title: 'Type' },
  { id: 1, title: 'Amount' },
  { id: 2, title: 'Hash' },
];

const colors = [
  '#f6a81e',
  '#5e62e6',
  '#3ddb42',
  '#21cbe6',
  '#6d6a6a',
  '#b7b3b3',
];

const TradeHistory = ({ bot, task }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const {
    topTen,
    errorTopTen,
    isTopTenLoading,
  } = useSelector((state) => ({
    topTen: state.topTen.data,
    errorTopTen: state.topTen.error,
    isTopTenLoading: state.topTen.isFetching,
  }));
  const topSix = topTen?.slice(0, 6);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30);

  const refreshData = () => {
    dispatch(fetchTopTenCurrencies());
  };

  const sortedTrades = task?.progress?.trades?.slice().sort((a, b) =>
   (new Date(b.date)).getTime() - (new Date(a.date)).getTime()
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedTrades?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => {
    if (pageNumber === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === "next" && currentPage < Math.ceil(sortedTrades?.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <Panel
      xl={12}
      xs={12}
      title={`Transaction history (${task?.progress?.trades?.length})`}
      isLoading={isTopTenLoading}
      refreshRequest={refreshData}
    >
      <ErrorAlert subTitle="Citations" error={errorTopTen} />
      <Table responsive striped>
        <thead>
          <tr>
            {header.map((item) => (
              <TradeHistoryTh key={item.id}>{item.title}</TradeHistoryTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((item, index) => (
            <tr key={index}>
              <td>
                <DashboardCoin
                  color={item.type === 'buy' ? '#b8e986' : '#ff4861'}
                >
                  {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                </DashboardCoin>
              </td>
              <td>≈ ${item.amount}</td>
              <td>{item.date} (UTC-0)</td>
              <td>
                <a
                  href={`https://bscscan.com/tx/${item.hash}`}
                  target="_blank"
                >
                  {item.hash}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        <PaginationButton
          onClick={() => paginate("prev")}
          disabled={currentPage === 1}
        >
          Previous
        </PaginationButton>
        {currentPage > 1 && (
          <PaginationButton onClick={() => paginate(currentPage - 1)}>
            {currentPage - 1}
          </PaginationButton>
        )}
        <PaginationButton isActive>{currentPage}</PaginationButton>
        {currentPage !== Math.ceil(sortedTrades?.length / itemsPerPage) && (
          <PaginationButton onClick={() => paginate(currentPage + 1)}>
            {currentPage + 1}
          </PaginationButton>
        )}
        <PaginationButton
          onClick={() => paginate("next")}
          disabled={
            currentPage === Math.ceil(sortedTrades?.length / itemsPerPage)
          }
        >
          Next
        </PaginationButton>
      </Pagination>
    </Panel>
  );
};

export default TradeHistory;

// Ajoutez vos composants stylisés ici

const DashboardCoin = styled.p`
  color: ${(props) => props.color};
  font-weight: 700;
`;

const TradeHistoryTh = styled.th`
  white-space: pre;
`;

// Composants stylisés pour la pagination
const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  background-color: ${(props) => (props.isActive ? '#007bff' : '#fff')};
  color: ${(props) => (props.isActive ? '#fff' : '#007bff')};
  border: 1px solid #007bff;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #007bff;
    color: #fff;
  }
`;
