import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import renderSelectField from '@/shared/components/form/Select';
import {
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import { marginRight } from '@/utils/directions';
import {
  WizardButtonToolbar,
  WizardFormContainer,
  WizardTitle,
} from '@/shared/components/form/WizardFormElements';

const WizardFormTwo = ({ onSubmit, previousPage, initialValues }) => {
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, values }) => (
        <WizardFormContainer horizontal onSubmit={handleSubmit}>
          <WizardTitle>Fill in the fields related to your task</WizardTitle>
          <FormGroup>
            <FormGroupLabel>Daily Injection Volume (over 24h in $)</FormGroupLabel>
            <FormGroupField>
              <Field
                name="liquidityInjection"
                component="input"
                type="number"
                placeholder="Enter amount (500 - 1000000)"
                min="500"
                max="1000000"
                isRequired
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Slippage</FormGroupLabel>
            <FormGroupField>
              <Field
                name="slippage"
                component="input"
                type="number"
                defaultValue='0.5'
                min="0.0"
                max="1"
                step="0.1"
                isRequired
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>gasPrice (gwei)</FormGroupLabel>
            <FormGroupField>
              <Field
                name="gasPrice"
                component="input"
                type="number"
                defaultValue='4'
                isRequired
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Token (<strong>CDT</strong>)</FormGroupLabel>
            <FormGroupField>
              <Field
                name="token"
                component="input"
                type="text"
                placeholder="Enter your token address"
                isRequired
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Token decimals</FormGroupLabel>
            <FormGroupField>
              <Field
                name="tokenDecimals"
                component="input"
                type="number"
                placeholder="Enter your token decimals"
                isRequired
              />
            </FormGroupField>
          </FormGroup>
          <WizardButtonToolbar>
            <Button variant="primary" type="button" className="previous" onClick={previousPage}>
              Back
            </Button>
            <Button variant="primary" className="next" type="submit">
              Next
            </Button>
          </WizardButtonToolbar>
        </WizardFormContainer>
      )}
    </Form>
  );
};

WizardFormTwo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default WizardFormTwo;
