import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import { useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import axios from 'axios';
import styled from 'styled-components';
import PasswordField from '@/shared/components/form/Password'; // Make sure this import is correct
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { marginLeft } from '@/utils/directions';
import { AccountButton, AccountButtons, ForgotFormGroup } from '../AccountElements';
import * as environement from '../../../../environement';

const RegisterForm = () => {
  const history = useHistory();
  const [error, setError] = useState(null);

  const handleSubmit = async (data) => {
    const { email, password, passwordConf } = data;

    if(!email || !password || !passwordConf) {
      setError('Please fill in all fields');
      return;
    }

    if(!email.includes('@')) {
      setError('Please enter a valid email address');
      return;
    }

    if(password.length < 8 || passwordConf.length < 8) {
      setError('Password and password confirmation must be at least 8 characters');
      return;
    }

    if(password.length > 20 || passwordConf.length > 20) {
      setError('Password and password confirmation must be less than 20 characters');
      return;
    }

    if(password.search(/[^a-zA-Z0-9!@#$%^&*]/) !== -1) {
      setError('Password and password confirmation must not contain any spaces');
      return;
    }

    if(password.search(email) !== -1) {
      setError('Password and password confirmation must not contain your email address');
      return;
    }

    if(password.search('password') !== -1) {
      setError('Password and password confirmation must not contain the word "password"');
      return;
    }

    if(password !== passwordConf) {
      setError('Password and password confirmation must match');
      return;
    }

    setError(null);

    try {
      const response = await axios.post(`${environement.config.apiUrl}/auth/signup`, data);
      if(response.status === 201) {
        history.push('/log_in');
      }
    }
    catch(err) {
      console.log(err);
      setError(err.response.data.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          <Alert variant="danger" show={!!error} style={{ width: 'auto' }}>
            {error}
          </Alert>
          <FormGroup>
            <FormGroupLabel>E-mail</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <AlternateEmailIcon />
              </FormGroupIcon>
              <Field
                name="email"
                component="input"
                type="email"
                placeholder="example@mail.com"
                required
                className="input-without-border-radius"
              />
            </FormGroupField>
          </FormGroup>
          <ForgotFormGroup>
            <FormGroupLabel>Password</FormGroupLabel>
            <FormGroupField>
              <Field
                name="password"
                component={PasswordField}
                placeholder="Password"
                className="input-without-border-radius"
                required
                keyIcon
              />
            </FormGroupField>
          </ForgotFormGroup>
          <ForgotFormGroup>
            <FormGroupLabel>Password Confirmation</FormGroupLabel>
            <FormGroupField>
              <Field
                name="passwordConf"
                component={PasswordField}
                placeholder="Password"
                className="input-without-border-radius"
                required
                keyIcon
              />
            </FormGroupField>
          </ForgotFormGroup>
          <AccountButton type="submit" variant="primary">
            Sign Up
          </AccountButton>
        </FormContainer>
      )}
    </Form>
  );
};

export default RegisterForm;

// region STYLES


// endregion
