import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie } from 'recharts';
import styled from 'styled-components';
import Panel from '@/shared/components/Panel';
import { colorAdditional, colorRed } from '@/utils/palette';
import {
  DashboardStatChartWrap,
  DashboardStatLabel,
  DashboardStatWrap,
} from '../../BasicStatisticComponents';
import Button from 'react-bootstrap/Button';

const data01 = [{ value: 50, fill: 'rgb(255, 255, 65)' },
  { value: 50, fill: '#eeeeee' }];

const BudgetStatistic = ({ bot, task }) => {
  const { t } = useTranslation('common');

  return (
    <Panel md={12} lg={6} xl={3} title="Task">
      <DashboardBudgetStatWrap>
        <DashboardStatMain>
          <DashboardStatMnTitle>Daily Injection Volume</DashboardStatMnTitle>
          <DashboardStatMainNumber>
            ${task?.liquidityInjection}
          </DashboardStatMainNumber>
          <hr />
        </DashboardStatMain>
        <DashboardStatData>
          <div>
            <DashboardStatDataNumber>${task?.progress?.liquidityInjected}</DashboardStatDataNumber>
            <p style={{ color: '#64677b' }}>Completed</p>
          </div>
          <div>
            <DashboardStatDataNumber>${task?.liquidityInjection - task?.progress?.liquidityInjected}</DashboardStatDataNumber>
            <p style={{ color: 'rgb(255, 255, 65)' }}>Remaining</p>
          </div>
        </DashboardStatData>
      </DashboardBudgetStatWrap>
    </Panel>
  );
};

export default BudgetStatistic;

// region STYLES

const DashboardBudgetStatWrap = styled(DashboardStatWrap)`
  text-align: center;
  flex-wrap: wrap;

  ${DashboardStatChartWrap} {
    margin: auto;
  }

  ${DashboardStatLabel} {
    font-size: 36px;
  }
`;

const DashboardStatMain = styled.div`
  width: 100%;

  hr {
    margin-bottom: 30px;
    margin-top: 40px;
  }
`;

const DashboardStatMnTitle = styled.p`
  color: ${colorAdditional};
`;

const DashboardStatMainNumber = styled.p`
  color: ${colorRed};
  font-size: 48px;
  line-height: 34px;
  margin-top: 15px;
`;

const DashboardStatData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;

  p {
    margin: 0;
  }
`;

const DashboardStatDataNumber = styled.p`
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
`;

// endregion
