import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
} from '@/shared/components/form/FormElements';
import { AccountButton } from '../AccountElements';
import { Alert } from 'react-bootstrap';

const ResetPasswordForm = () => {
  const [error, setError] = useState(null);

  const handleSubmit = async (data) => {
    const { email } = data;
  
    if (!email) {
      setError('Please fill in all fields');
      return;
    }

    if (!email.includes('@')) {
      setError('Please enter a valid email address');
      return;
    }

    setError(null);

    // TODO: Add recovery logic here
  };

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          <Alert
            variant="danger"
            show={!!error}
            style={{ width: 'auto' }}
          >
            {error}
          </Alert>
          <FormGroup>
            <FormGroupField>
              <FormGroupIcon>
                <AlternateEmailIcon />
              </FormGroupIcon>
              <Field
                name="email"
                id="email"
                component="input"
                type="email"
                placeholder="example@mail.com"
                className="input-without-border-radius"
                required
              />
            </FormGroupField>
          </FormGroup>
          <AccountButton variant="primary" type="submit">
            Reset Password
          </AccountButton>
        </FormContainer>
      )}
    </Form>
  );
};

export default ResetPasswordForm;
