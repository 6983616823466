import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '@/shared/components/Panel';
import Tooltip from '@/shared/components/Tooltip';
import PropTypes from 'prop-types';
import { getShortCryptoAddress } from '@/utils/helpers';
import { Button } from '@/shared/components/Button';
import {
  WalletInfoWrapper,
  WalletStatRowTitle,
  WalletStatRowValue,
  WalletStatRowWrapper,
} from '../styled';
import { SidebarLinkIcon } from '../../../Layout/components/sidebar/SidebarLink';
import Spinner from 'react-bootstrap/Spinner';

const WalletInfo = ({ ifMainWallet, native, nativeBN, dir }) => {
  const { t } = useTranslation('common');
  const WALLET_ADDRESS = ifMainWallet;

  const onCopyWalletAddress = () => {
    navigator.clipboard.writeText(ifMainWallet);
  };

  const panelFooter = (
    <span style={{ color: 'white', fontStyle: 'italic', fontSize: '11px', color: 'orange' }}>
      The main wallet that we provide to you is specific to you. <br /> No one has access to this wallet except yourself when you request it.
      {ifMainWallet && (
        <div>
          <br /><br />
          <Button variant="primary" type="submit">Receive the private key</Button>
        </div>
      )}
    </span>
  );

  return (
    <Panel xs={12} lg={6} xxl={3} title="Wallet EVM" panelFooter={panelFooter}>
      <WalletInfoWrapper>
        <WalletStatRowWrapper>
          <WalletStatRowTitle>{t('wallet.address')}</WalletStatRowTitle>
          <WalletStatRowValue variant="small">
            {!ifMainWallet ? (
              <span>N/A</span>
            ) : (
              <span>{getShortCryptoAddress(WALLET_ADDRESS)}</span>
            )}
            <Tooltip text="Copy wallet address" dir={dir} placement="top">
              <SidebarLinkIcon className="lnr lnr-book" onClick={onCopyWalletAddress} />
            </Tooltip>
          </WalletStatRowValue>
        </WalletStatRowWrapper>

        <WalletStatRowWrapper>
          <WalletStatRowTitle>{t('wallet.balance')}</WalletStatRowTitle>
          <WalletStatRowValue>
            {!ifMainWallet ? (
              <span>N/A</span>
            ) : (
              <>
              {!nativeBN ? (
                <Spinner animation="border" size="sm" />
              ) : (
                `${nativeBN} BNB`
              )}
              </>
            )}
          </WalletStatRowValue>
        </WalletStatRowWrapper>

        <WalletStatRowWrapper>
          <WalletStatRowTitle>{t('wallet.estimated_value')}</WalletStatRowTitle>
          <WalletStatRowValue>
            {!ifMainWallet ? (
              <span>N/A</span>
            ) : (
              <>
              {!native ? (
                <Spinner animation="border" size="sm" />
              ) : (
                `$${native} USDT`
              )}
              </>
            )}
          </WalletStatRowValue>
        </WalletStatRowWrapper>
      </WalletInfoWrapper>
    </Panel>
  );
};

WalletInfo.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default WalletInfo;
