import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BasicTable from '../../Tables/BasicTables/components/BasicTable';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import Badge from '@/shared/components/Badge';
import { Button } from '@/shared/components/Button';
import axios from 'axios';
import * as environement from '../../../environement';
import { useHistory } from 'react-router-dom';

const Manage = () => {
  const { t } = useTranslation('common');
  const rtl = useSelector(state => state.rtl);
  const history = useHistory();
  const [key, setKey] = useState('user');

  useEffect(() => {
    checkIfAdmin();
    getUsers();
    getBots();
    getPayment();
  }, []);

  const [user, setUser] = useState([]);
  const [bots, setBots] = useState([]);
  const [payment, setPayment] = useState([]);

  const checkIfAdmin = async () => {
    try {
      const response = await axios.get(`${environement.config.apiUrl}/user/me`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 200) {
        if(response.data.role !== 'superadmin') {
          history.push('/wallet');
        }
       }
    }
    catch(err) {
      console.log(err);
    }
  }

  const getUsers = async () => {
    try {
      const response = await axios.get(`${environement.config.apiUrl}/user`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 200) {
        console.log(response.data);
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getBots = async () => {
    try {
      const response = await axios.get(`${environement.config.apiUrl}/bot`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 200) {
        setBots(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getPayment = async () => {
    try {
      const response = await axios.get(`${environement.config.apiUrl}/user/payments`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 200) {
        setPayment(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            Super Admin - Manage app
          </h3>
        </Col>
      </Row>
      <Row>
      <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
      style={{ color: '#DDDDDD' }}
    >
      <Tab eventKey="user" title="User Management">
        <p style={{ color: '#ddddd' }}>
        <Card>
          <CardBody>
            <CardTitleWrap>
              <CardTitle>User management</CardTitle>
            </CardTitleWrap>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>License</th>
                </tr>
              </thead>
              <tbody>
                {user.map(item => {
                  return (
                    <tr key={item._id}>
                      <td>{item._id}</td>
                      <td>{item.email}</td>
                      {item.role === 'superadmin' && (
                        <td><Badge bg="danger">Super Admin</Badge></td>
                      )}
                      {item.role === 'user' && (
                        <td><Badge bg="primary">User</Badge></td>
                      )}
                      {item.license === '' && (
                        <td><Badge bg="danger">No</Badge></td>
                      )}
                      {item.license !== '' && (
                        <td><Badge bg="success">Yes or expired</Badge></td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
              <tbody>
            </tbody>
            </Table>
          </CardBody>
        </Card>
        </p>
      </Tab>
      <Tab eventKey="bot" title="Bot management">
        <p style={{ color: '#ddddd' }}>
        <Card>
          <CardBody>
            <CardTitleWrap>
              <CardTitle>Bot management</CardTitle>
            </CardTitleWrap>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Blockchain</th>
                  <th>Exchange</th>
                  <th>Status</th>
                  <th>TaskId</th>
                  <th>Owner</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {bots.map(item => {
                const statusConfig = {
                  running: { badge: 'success', buttons: [<Button variant="warning">Break</Button>, <Button variant="danger">Stop</Button>] },
                  break: { badge: 'warning', buttons: [<Button variant="primary">Continue</Button>, <Button variant="danger">Stop</Button>] },
                  stopped: { badge: 'danger', buttons: [<Button variant="success">Start</Button>] },
                };

                const { badge, buttons } = statusConfig[item.status] || { badge: 'secondary', buttons: [] };

                return (
                  <tr key={item._id}>
                    <td>{item._id}</td>
                    <td>{item.blockchain}</td>
                    <td>{item.exchange}</td>
                    {item.status === 'running' && (
                      <td><Badge bg="success">Running</Badge></td>
                    )}
                    {item.status === 'stopped' && (
                      <td><Badge bg="danger">Stopped</Badge></td>
                    )}
                    {item.status === 'break' && (
                      <td><Badge bg="warning">Break</Badge></td>
                    )}
                    {item.status === 'breakByAdmin' && (
                      <td><Badge bg="warning">Break by Admin</Badge></td>
                    )}
                    <td>{item.taskId}</td>
                    <td>{item.owner}</td>
                    <td>
                      {buttons.map((button, index) => (
                        <React.Fragment key={index}>{button}</React.Fragment>
                      ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            </Table>
          </CardBody>
        </Card>
        </p>
      </Tab>
      <Tab eventKey="payment" title="Payment history">
      <p style={{ color: '#ddddd' }}>
        <Card>
          <CardBody>
            <CardTitleWrap>
              <CardTitle>Payment history</CardTitle>
            </CardTitleWrap>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>User</th>
                  <th>Plan</th>
                  <th>Price</th>
                  <th>Wallet Receiver</th>
                  <th>Token Sent</th>
                </tr>
              </thead>
              <tbody>
                {payment.map(item => {
                  return (
                    <tr key={item._id}>
                      <td>{item._id}</td>
                      <td>{item.date}</td>
                      {item.status === 'paid' && (
                        <td><Badge bg="success">Paid</Badge></td>
                      )}
                      {item.status === 'init' && (
                        <td><Badge bg="warning">Init</Badge></td>
                      )}
                      {item.status === 'failed' && (
                        <td><Badge bg="danger">Failed</Badge></td>
                      )}
                      <td>{item.user}</td>
                      <td>{item.plan}</td>
                      <td>${item.price}</td>
                      <td>{item.walletReceiver}</td>
                      <td>{item.tokenSent}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
        </p>
      </Tab>
      </Tabs>
      </Row>
    </Container>
  );
};

export default Manage;
