import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CardBasic from './components/CardBasic';
import CardSpecial from './components/CardSpecial';
import CardPro from './components/CardPro';
import CardPremium from './components/CardPremium';
import { checkLicense } from '@/api';
import { useHistory } from 'react-router-dom';
import { Table } from '@/shared/components/TableElements';
import axios from 'axios';
import * as environement from '../../../environement';

const PricingCards = () => {
  const history = useHistory();
  const [license, setLicense] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const [payment, setPayment] = useState([]);

  useEffect(() => {
    checkLicense().then((res) => {
      if(res.data.valid === true) {
        setLicense(true);
        const expirationDateT = new Date(res.data.timestamp * 1000);
        setExpirationDate(expirationDateT.toString());
      } else {
        setLicense(false);
        setExpirationDate(null);
      }
    });
  }, []);

  useEffect(() => {
    getPayements();
  }, []);

  const getPayements = async () => {
    const response = await axios.get(`${environement.config.apiUrl}/user/payments`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
    setPayment(response.data);
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Buy your license now</h3>
          <h3 className="page-subhead subhead">Here you will find all the plans that we offer you</h3>
        </Col>
      </Row>
      {expirationDate && (
      <p style={{ marginLeft: '10px', marginBottom: '10px' }}>
        <h4 style={{ color: 'rgb(255, 255, 65)' }}>
          You have a license <br />
          Expiration date: <strong style={{ color: '#ddddd' }}>
            {expirationDate}
          </strong>
        </h4>
      </p>
      )}
      <Row dir="ltr">
        <CardBasic license={license} />
        <CardSpecial license={license} />
        <CardPro license={license} />
        <CardPremium license={license} />
      </Row>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Payment history</h3>
          <br />
          <Table responsive hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Plan</th>
                  <th>Price</th>
                  <th>Wallet Receiver</th>
                  <th>Token sent</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {payment.length === 0 && (
                  <tr>
                    <td colSpan="8">
                      <center>
                        You don't have any payment history.
                      </center>
                    </td>
                  </tr>
                )}
                {payment.map(item => {
                  return (
                    <tr key={item._id}>
                      <td>{item._id}</td>
                      <td>{item.date}</td>
                      <td style={{ color: item.status === 'paid' ? 'green' : 'red' }}>
                        {item.status === 'init' && item.progress === false ? 'Incomplete' : null}
                        {item.status === 'failed' && item.progress === false ? 'Failed' : null}
                        {item.status === 'paid' && item.progress === false ? 'Paid' : null}
                        {item.progress === true ? 'In Progress' : null}
                      </td>
                      <td>{item.plan}</td>
                      <td>{item.price} USDT</td>
                      <td>{item.walletReceiver}</td>
                      <td>{item.tokenSent}</td>
                      <td>
                        {item.status !== 'init' ? (
                          <>
                            /
                          </>
                        ) : (
                          <button onClick={() => history.push(`/payment/one_month`)} className="btn btn-primary">Details</button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
          </Table>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default PricingCards;
