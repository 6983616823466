import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { Badge } from 'react-bootstrap';
import { checkPaymentAction, initPayment } from '@/api';
import axios from 'axios';
import * as environement from '../../../../environement';


const PaymentCard = ({ plan, price }) => {
  const history = useHistory();
  const [paymentId, setPaymentId] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [checkPayment, setCheckPayment] = useState(false);
  const [planDetails, setPlanDetails] = useState(null);
  const [priceDetails, setPriceDetails] = useState(null);
  const [alreadyInit, setAlreadyInit] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentInQueu, setPayementInQueu] = useState(false);
  
  const fetchData = async () => {
    initPayment(plan, parseFloat(price)).then((response) => {
      handleSetInfos(response);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSetInfos = async (response) => {
    if(response.data.message === 'Payment already initialized') {
      setAlreadyInit(true);
    }
    if(response.data.message === "Payment already in progress") {
      setCheckPayment(false);
      setAlreadyInit(false);
      setPayementInQueu(true);
    }
    setPaymentId(response.data.paymentId);
    setWallet(response.data.walletReceiver);
    setPriceDetails(response.data.price);
    setPlanDetails(response.data.plan);
  }

  const handleCheckPayment = async () => {
    setCheckPayment(true);
    try {
      const response = await axios.get(`${environement.config.apiUrl}/user/checkPayment`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.data === 'Payment failed') {
        setCheckPayment(false);
        setPaymentFailed(true);
      }
      if(response.data === 'Payment done') {
        setCheckPayment(false);
        setPaymentSuccess(true);
        setTimeout(() => {
          history.push('/subscription');
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleCancelPayment = async () => {
    const cancel = await axios.get(`${environement.config.apiUrl}/user/cancelPayment` ,{ headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
    history.push('/subscription');
  }

  const handleSubscriptionPage = async () => {
    history.push('/subscription');
  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>License order review</CardTitle>
          </CardTitleWrap>
          <PaymentWrap style={{ color: '#DDDDD' }}>
            <img style={{ width: '10%', marginLeft: '20px' }} src={process.env.PUBLIC_URL + '/img/logo/binance.png'} />
            <img style={{ width: '9%', marginLeft: '20px' }} src={process.env.PUBLIC_URL + '/img/logo/usdt.png'} />
            <p style={{ marginLeft: '20px', marginTop: '20px' }}>
              Payement <strong>#{paymentId}</strong> <br />
              You buy the license for « <strong style={{ fontSize: '20px', color: 'rgb(255, 255, 65)' }}>{planDetails ? planDetails : plan}</strong> » for the price of « <strong style={{ fontSize: '20px', color: 'rgb(255, 255, 65)' }}>{priceDetails ? priceDetails : price}.00 USDT</strong> »
              {alreadyInit === true && !paymentFailed && !paymentSuccess && !paymentInQueu && (
                <Alert variant='danger' style={{ marginTop: '5px', opacity: '0.9' }}>
                  <center>
                    You already have a payment <strong>initialized</strong> ({planDetails}), finalized it or <strong>cancel</strong> it using the button at bottom of the page.
                  </center>
                </Alert>
              )}
              {!paymentFailed && !paymentSuccess && !paymentInQueu && (
                <>
                <Alert variant='warning' style={{ marginTop: '5px', opacity: '0.9' }}>
                <center>
                  Make sure to send the exact amount to the <strong>BINANCE SMART CHAIN</strong> and in <strong>USDT</strong> (BEP20) <br />
                  Once you have sent the <strong>USDT</strong>, click on the "<strong>I paid.</strong>." button.
                </center>
                </Alert>
                <Alert variant='info' style={{ marginTop: '5px', opacity: '0.9' }}>
                <center>
                  Send <strong>{priceDetails ? priceDetails : price}.00 USDT</strong> to the following address: <br /><br />
                  « <strong style={{ fontSize: '20px' }}>{wallet}</strong> »
                  <br /><br />
                  <Spinner animation="border" variant="info" />
                  <br /><br />
                  <Button variant="success" disabled={checkPayment ? true : false} onClick={handleCheckPayment}>
                  {checkPayment && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp; {checkPayment ? ( <span>Payment verification</span> ) : <span>I paid, check my payment</span> }
                  </Button>
                </center>
                </Alert>
                </>
              )}
              {paymentFailed && (
                <Alert variant='danger' style={{ marginTop: '5px', opacity: '0.9' }}>
                  <center>
                    Your payment has <strong>failed</strong>, please try again. <br />
                    Contact us on <strong>discord</strong> with the <strong>error code</strong> that we sent you by <strong>email</strong> if you <strong>are sure</strong> that you sent the <strong>amount</strong>.
                  </center>
                </Alert>
              )}
              {paymentSuccess && (
                <Alert variant='success' style={{ marginTop: '5px', opacity: '0.9' }}>
                  <center>
                    Your payment has been <strong>validated</strong>. <br />
                    You will be redirected to the <strong>subscription</strong> page in a few seconds.
                  </center>
                </Alert>
              )}
              {paymentInQueu && (
                <Alert variant='info' style={{ marginTop: '5px', opacity: '0.9' }}>
                  <center>
                    Your payment is being <strong>executed in the background</strong>, go to the <strong>subscriptions page</strong> to view its status.
                  </center>
                </Alert>
              )}
            </p>
            <center>
              {!checkPayment && !paymentSuccess && (
                <>
                <br />
                <Button variant="primary" type="submit" onClick={!paymentInQueu ? handleCancelPayment : handleSubscriptionPage}>
                  {paymentInQueu ? 'Go to subscription page' : 'Cancel and return to explore the plans'}
                </Button>
                </>
              )}
            </center>
          </PaymentWrap>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PaymentCard;

// region STYLES

const PaymentWrap = styled.div`
  max-width: 740px;
`;

// endregion