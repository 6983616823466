import React, { useState, useEffect } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import TradeHistory from './components/TradeHistory';
import { useSelector } from 'react-redux';
import BudgetStatistic from '../OnLineMarketing/components/BudgetStatistic';
import BudgetStatistic2 from '../OnLineMarketing/components/BudgetStatistic2';
import { useHistory } from 'react-router-dom';
import SessionShort from '../App/components/SessionShort';
import axios from 'axios';
import Badge from 'react-bootstrap/Badge';
import { checkIfBot, getBalances, getWorkers, checkLicense, apiUrl } from '@/api';
import ABTestingAnalytics from '../OnLineMarketing/components/ABTestingAnalytics';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import * as environement from '../../../environement';
import WizardFormThree from '../../Form/ReactFinalForm/WizardForm/components/WizardFormThree';
import { useQuery } from '@tanstack/react-query';

const FinanceDashboard = () => {
  const history = useHistory();
  const [bot, setBot] = useState({});
  const [task, setTask] = useState({});
  const [balances, setBalances] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [errorDelete, setErrorDelete] = useState(false);
  const [show, setShow] = useState(false);

  // form cfg
  const [slippage, setSlippage] = useState(0);
  const [gasPrice, setGasPrice] = useState(0);
  const [strategy, setStrategy] = useState({});
  // const [chances, setChances] = useState(0);
  const [liquidityInjection, setLiquidityInjection] = useState(0);
  const [amountPerTransaction, setAmountPerTransaction] = useState(0);
  const [cfgTransactions, setcfgTransactions] = useState(0);
  const [cfgInterval, setcfgInterval] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { rtl } = useSelector((state) => ({
    rtl: state.rtl,
  }));

  const fetchBot = async () => {
    const license = await checkLicense();
    if (license.data.valid === false) {
      history.push('/subscription');
    }

    if (show) {
      return ;
    }

    const botAndTask = await checkIfBot();
    setBot(botAndTask.data.bot);
    setTask(botAndTask.data.task);

    setSlippage(botAndTask.data.task.slippage);
    setGasPrice(botAndTask.data.task.gasPrice);
    setStrategy(botAndTask.data.task.strategy);
    // setChances(botAndTask.data.task.strategy.classic.chances);
    setLiquidityInjection(botAndTask.data.task.liquidityInjection);
    setAmountPerTransaction(botAndTask.data.task.amountPerTransaction);
    setcfgTransactions(botAndTask.data.task.transactions);
    setcfgInterval(botAndTask.data.task.interval);

    fetchBalances(botAndTask.data.task.token.address, botAndTask.data.task.token.decimals);
    fetchWorkers();

    return true;
  };

  const fetchBalances = async (tokenAddress, tokenDecimals) => {
    const balances = await getBalances(tokenAddress, tokenDecimals);
    setBalances(balances.data);
  };

  const fetchWorkers = async () => {
    const workers = await getWorkers();
    setWorkers(workers.data);
  };

  const fetchBotQuery = useQuery({
    queryFn: async () => {
      return await fetchBot();
    },
    interval: 10000,
    enabled: !show
  });

  const handleDeleteBot = async () => {
    try {
      await axios.delete(`${environement.config.apiUrl}/bot`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      history.push('/bot');
    } catch (error) {
      setErrorDelete(true);
      setTimeout(() => {
        setErrorDelete(false);
      }, 10000);
    }
  };

  const handleValidateEdit = async () => {
    try {
      await axios.post(
        `${environement.config.apiUrl}/bot/cfg`,
        { slippage, gasPrice, strategy, liquidityInjection, amountPerTransaction, transactions: cfgTransactions, interval: cfgInterval },
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      );
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ backgroundColor: '#23232a', color: '#dddddd', borderRadius: '0px' }}>
          <Modal.Title>Edit task config</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#23232a', color: '#dddddd' }}>
          <Form style={{ backgroundColor: '#23232a' }}>
            <center>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>24h volume in Dollar</Form.Label>
                <Form.Control
                  type="number"
                  value={liquidityInjection}
                  onChange={(e) => setLiquidityInjection(e.target.value)}
                  style={{ backgroundColor: '#23232a', color: '#dddddd', width: 'auto' }}
                />
              </Form.Group>
              <WizardFormThree
                  previousPage={() => {}}
                  onSubmit={() => {}}
                  initialValues={{ liquidityInjection: Number(liquidityInjection) }}
                  onChange={(changes) => {
                    console.log(changes);
                    setAmountPerTransaction(changes.amountPerTransaction);
                    setcfgTransactions(changes.transactions);
                    setcfgInterval(changes.interval);
                  }}
                  editing={true}
                />
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Slippage</Form.Label>
                <Form.Control
                  type="number"
                  value={slippage}
                  onChange={(e) => setSlippage(e.target.value)}
                  min="0.0"
                  max="1"
                  step="0.1"
                  style={{ backgroundColor: '#23232a', color: '#dddddd', width: 'auto' }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Gas Price (gwei)</Form.Label>
                <Form.Control
                  type="number"
                  value={gasPrice}
                  onChange={(e) => setGasPrice(e.target.value)}
                  style={{ backgroundColor: '#23232a', color: '#dddddd', width: 'auto' }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Strategy</Form.Label>
                <Form.Control
                as="select"
                value={strategy.name}
                onChange={(e) => {
                  if (e.target.value === 'flip') {
                    setStrategy({ name: 'flip', way: 'buy' });
                  } else if (e.target.value === 'classic') {
                    setStrategy({ name: 'classic', chances: 5 });
                  }
                }}
                >
                  <option value="classic">Classic, play with luck</option>
                  <option value="flip">Flip, one buy, one sell</option>
                </Form.Control>
              </Form.Group>
              {strategy.name === 'classic' && (<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Chance of buying your token out of 10</Form.Label>
                <Form.Control
                  type="number"
                  value={strategy.chances}
                  onChange={(e) => setStrategy({... strategy, chances: Number(e.target.value)})}
                  style={{ backgroundColor: '#23232a', color: '#dddddd', width: 'auto' }}
                />
              </Form.Group>)}
            </center>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#23232a', color: '#dddddd', borderRadius: '0px' }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleValidateEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            Bot details - {bot?.status === 'running' && <span>{task?.startDate} (UTC-0)</span>} &nbsp;
            <Badge pill bg="primary" style={{ marginRight: '5px' }}>
              {bot?.blockchain}
            </Badge>
            <Badge pill bg="info">
              {bot?.exchange}
            </Badge>
            {<Button variant="danger" style={{ float: 'right', marginRight: '5px' }} size="sm" onClick={handleDeleteBot} disabled={bot?.status !== 'stopped'}>Delete this bot</Button>}
            {<Button variant="warning" style={{ float: 'right', marginRight: '5px' }} size="sm" onClick={handleShow}>Edit config</Button>}
          </h3>
        </Col>
      </Row>
      {errorDelete && (
        <Alert variant="danger">
          <center>
            You still <strong>have sub wallets</strong>, make sure to <strong>stop the bot correctly</strong> if you have not already done so,
            then delete your sub wallets <strong>1 by 1</strong> so as not to <strong>forget the liquidity on them</strong>.
          </center>
        </Alert>
      )}
      <Row>
        <BudgetStatistic bot={bot} task={task} />
        <BudgetStatistic2 bot={bot} task={task} />
        <SessionShort bot={bot} task={task} balances={balances} workers={workers} />
      </Row>

      <Row>
        <ABTestingAnalytics dir={rtl.direction} bot={bot} task={task} />
        <TradeHistory bot={bot} task={task} />
      </Row>
    </Container>
  );
};

export default FinanceDashboard;
