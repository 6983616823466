import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { colorBackgroundBody } from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';
import Layout from '../../../Layout/index';
import Wallet from '../../../Dashboards/Wallet';
import PricingCards from '../../../DefaultPage/PricingCards';
import Payment from '../../../ECommerce/Payment/index';
import FinanceDashboard from '../../../Dashboards/Finance';
import WizardForm from '../../../Form/ReactFinalForm/WizardForm/index';
import Manage from '../../../Dashboards/Manage';

export default () => (
  <div>
    <Layout />
    <ContainerWrap>
      <Route exact path="/bot" component={WizardForm} />
      <Route exact path="/bot_details" component={FinanceDashboard} />
      <Route exact path="/manage" component={Manage} />
      <Route path="/wallet" component={Wallet} />
      <Route path="/subscription" component={PricingCards} />
      <Route path="/payment/:plan" component={Payment} />
    </ContainerWrap>
  </div>
);

// region STYLES

const ContainerWrap = styled.div`
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    ${paddingLeft}: 250px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 150px;
  }
`;

// endregion
