import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap'; // Importez également Row
import ShortReminders from '../../ECommerce/components/ShortReminders';
import { Card } from '@/shared/components/Card';
import FlashIcon from 'mdi-react/FlashIcon';
import {
  MobileAppWidgetLine,
  MobileAppWidgetStat,
  MobileAppWidgetTitle,
  MobileWidgetIconDown,
  MobileWidgetIconUp
} from '../MobileWidgetDashboardElements';
import {
  DashboardWidgetCard,
} from '../../BasicDashboardComponents';
import BasicTable from '@/containers/Tables/BasicTables/components/BasicTable';

const SessionShort = ({ bot, task, balances, workers }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Col md={4}>
        <ShortReminders bot={bot} task={task} balances={balances} workers={workers} />
      </Col>
      <Col md={2}>
        <Card style={{ height: '40%' }}>
          <DashboardWidgetCard>
            <div>
              <MobileAppWidgetLine className=" mobile-app-widget__top-line--lime">
                <MobileAppWidgetStat color="#b8e986">${task?.progress?.buyAmount}</MobileAppWidgetStat>
                <MobileWidgetIconUp style={{ fill: '#b8e986' }} />
              </MobileAppWidgetLine>
              <MobileAppWidgetTitle style={{ margin: '0px 0px 0px' }}>
                <h5>Buy</h5>
              </MobileAppWidgetTitle>
            </div>
          </DashboardWidgetCard>
          <br />
          <DashboardWidgetCard>
            <div>
              <MobileAppWidgetLine>
                <MobileAppWidgetStat color="#ff4861">${task?.progress?.sellAmount}</MobileAppWidgetStat>
                <MobileWidgetIconDown style={{ fill: '#ff4861' }} />
              </MobileAppWidgetLine>
              <MobileAppWidgetTitle style={{ margin: '0px 0px 0px' }}>
                <h5>Sell</h5>
              </MobileAppWidgetTitle>
            </div>
          </DashboardWidgetCard>
          <br />
          <DashboardWidgetCard>
            <div>
              <MobileAppWidgetLine>
                <MobileAppWidgetStat color="#f6da6e">${task?.progress?.fees}</MobileAppWidgetStat>
                <FlashIcon style={{ fill: '#f6da6e' }} />
              </MobileAppWidgetLine>
              <MobileAppWidgetTitle style={{ margin: '0px 0px 0px' }}>
                <h5>Fees</h5>
              </MobileAppWidgetTitle>
            </div>
          </DashboardWidgetCard>
          <br />
          <DashboardWidgetCard>
            <div>
              <MobileAppWidgetLine>
                <MobileAppWidgetStat color="#f6da6e">${task?.progress?.swapLoss ?? '0'}</MobileAppWidgetStat>
                <FlashIcon style={{ fill: '#f6da6e' }} />
              </MobileAppWidgetLine>
              <MobileAppWidgetTitle style={{ margin: '0px 0px 0px' }}>
                <h5>Swap Loss</h5>
              </MobileAppWidgetTitle>
            </div>
          </DashboardWidgetCard>
        </Card>
      </Col>
    </>
  );
};

export default SessionShort;
