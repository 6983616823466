import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import PasswordField from '@/shared/components/form/Password';
import renderSelectField from '@/shared/components/form/Select';
import {
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import { WizardButtonToolbar, WizardFormContainer, WizardTitle } from '@/shared/components/form/WizardFormElements';

const WizardFormOne = ({ onSubmit, initialValues }) => {

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, values }) => (
        <WizardFormContainer horizontal onSubmit={handleSubmit}>
          <WizardTitle>Complete the fields related to your bot</WizardTitle>
          <FormGroup>
            <FormGroupLabel>Blockchain</FormGroupLabel>
            <FormGroupField>
              <Field
                name="blockchain"
                component={renderSelectField}
                type="text"
                options={[
                  { value: 'BNB Smart Chain', label: 'BNB Smart Chain' },
                ]}
                placeholder="Select blockchain"
                isRequired
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Exchange (Dex)</FormGroupLabel>
            <FormGroupField>
              <Field
                name="dex"
                component={renderSelectField}
                type="text"
                options={[
                  { value: 'Pancakeswap V2', label: 'Pancakeswap V2' },
                  { value: 'Pancakeswap V3', label: 'Pancakeswap V3' },
                  { value: 'Uniswap V3', label: 'Uniswap V3' },
                ]}
                placeholder="Select exchange"
                isRequired
              />
            </FormGroupField>
          </FormGroup>
          <WizardButtonToolbar>
            <Button
              variant="primary"
              type="button"
              disabled
              className="previous"
            >
              Back
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="next"
            >
              Next
            </Button>
          </WizardButtonToolbar>
        </WizardFormContainer>
      )}
    </Form>
  );
};

WizardFormOne.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default WizardFormOne;
