import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie } from 'recharts';
import styled from 'styled-components';
import Panel from '@/shared/components/Panel';
import { Button } from '@/shared/components/Button';
import Badge from 'react-bootstrap/Badge';
import { colorAdditional, colorRed } from '@/utils/palette';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import * as environement from '../../../../environement';
import {
  DashboardStatChartWrap,
  DashboardStatLabel,
  DashboardStatWrap,
} from '../../BasicStatisticComponents';
import { Alert } from 'react-bootstrap';

const data01 = [{ value: 50, fill: 'rgb(255, 255, 65)' },
  { value: 50, fill: '#eeeeee' }];

const BudgetStatistic2 = ({ bot, task }) => {
  const { t } = useTranslation('common');
  const [state, setState] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [timeWait, setTimeWait] = useState(0);

  useEffect(() => {
    setState(bot?.status);

    setTimeWait(task?.progress?.timeWait);
  
  }, [bot, task]);

  const handleStart = async () => {
    setState('propagate');

    try {
      const response = await axios.get(`${environement.config.apiUrl}/bot/start/init`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 200) {
        setState('running');
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setState('stopped');
      setTimeWait(0);
      setTimeout(() => {
        setErrorMsg(null);
      }, 10000);
    }
  };

  const handleContinue = async () => {
    try {
      setState('running');

      await axios.get(`${environement.config.apiUrl}/bot/start/continue`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setState('break');
      setTimeWait(0);
      setTimeout(() => {
        setErrorMsg(null);
      }, 10000);
    }
  };

  const handleBreak = async () => {
    try {
      const response = await axios.get(`${environement.config.apiUrl}/bot/status/break`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 200) {
        setState('break');
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setState('running');
      setTimeWait(0);
      setTimeout(() => {
        setErrorMsg(null);
      }, 10000);
    }
  };

  const handleStop = async () => {
    setState('processStopping');

    try {
      const response = await axios.get(`${environement.config.apiUrl}/bot/stop`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      if(response.status === 200) {
        setState('stopped');
        setTimeWait(0);
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setState('running');
      setTimeout(() => {
        setErrorMsg(null);
      }, 10000);
    }
  };

  return (
    <Panel md={12} lg={6} xl={3} title="Controls and state">
      <DashboardBudgetStatWrap>
        <DashboardStatMain>
          <DashboardStatMainNumber2>
          {state !== 'breakByAdmin' && (
            <>
            {state === 'running' ? <Badge bg="success">Running <Spinner animation="border" size="sm" /></Badge> : null}
            {state === 'propagate' ? <Badge bg="info">Propagate <Spinner animation="border" size="sm" /></Badge> : null}
            {state === 'processStopping' ? <Badge bg="danger">Stopping <Spinner animation="border" size="sm" /></Badge> : null}
            {state === 'stopped' ? <Badge bg="danger">Stopped</Badge> : null}
            {state === 'break' ? <Badge bg="warning">Break</Badge> : null}
            </>
          )}
          {state === 'breakByAdmin' ? <Badge bg="warning">Break by Admin (MaJ)</Badge> : null}
          </DashboardStatMainNumber2>
          {state === 'running' && (
            <>
            {task?.progress?.timeWait > 0 ? (
              <span style={{ color: '#FFFFFF' }}>Sleep for <strong>{timeWait}s</strong> for next trade..</span>
            ) : (
              <span style={{ color: '#FFFFFF' }}>Trade in progress..</span>
            )}
            </>
          )}
          <br />
        </DashboardStatMain>
        <DashboardStatData>
        {state !== 'breakByAdmin' && (
          <>
          <div>
            <Button type="button" onClick={state === 'break' ? handleContinue : handleStart} variant={state === 'break' ? 'primary' : 'success'} disabled={state === 'propagate' || state === 'running' || state === 'processStopping' ? true : false}>
              {state === 'break' ? 'Continue' : 'Start'}
            </Button>
          </div>
          <div>
            <Button type="button" onClick={handleBreak} variant="warning" disabled={state === 'running' && state !== 'break' && state !== 'stopped' ? false : true }>
              Break
            </Button>
          </div>
          <div>
            <Button type="button" onClick={handleStop} variant="danger" disabled={state === 'running' || state === 'break' ? false : true }>
              Stop
            </Button>
          </div>
          </>
        )}
        </DashboardStatData>
        {errorMsg && ( <Alert variant="danger">{errorMsg}</Alert>)}
      </DashboardBudgetStatWrap>
    </Panel>
  );
};

export default BudgetStatistic2;

// region STYLES

const DashboardBudgetStatWrap = styled(DashboardStatWrap)`
  text-align: center;
  flex-wrap: wrap;

  ${DashboardStatChartWrap} {
    margin: auto;
  }

  ${DashboardStatLabel} {
    font-size: 36px;
  }
`;

const DashboardStatMain = styled.div`
  width: 100%;

  hr {
    margin-bottom: 30px;
    margin-top: 40px;
  }
`;

const DashboardStatMnTitle = styled.p`
  color: ${colorAdditional};
`;

const DashboardStatMainNumber2 = styled.p`
  color: white;
  font-size: 28px;
  line-height: 34px;
  margin-top: 15px;
  padding: 5%;
`;

const DashboardStatData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;

  p {
    margin: 0;
  }
`;

const DashboardStatDataNumber = styled.p`
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
`;

// endregion
