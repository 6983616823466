import React from 'react';
import LogInForm from '@/shared/components/account/loginForm/LogInForm';
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountOr,
  AccountSocial,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';

const LogIn = () => {
  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>Welcome to
              <AccountLogo> AMP
                <AccountLogoAccent>BIT</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
            <h4 className="subhead">Start managing your market</h4>
          </AccountHead>
          <LogInForm
            form="log_in_form"
          />
          <AccountOr>
            <p>Our services</p>
          </AccountOr>
          <AccountSocial>
            <img src={process.env.PUBLIC_URL + '/img/logo/pancakeswap.png'} style={{ width: '40%' }}></img> &nbqp;
            <img src={process.env.PUBLIC_URL + '/img/logo/uniswap.png'} style={{ width: '40%' }}></img>
          </AccountSocial>
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  );
};

export default LogIn;